import React, { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import '@/styles/mixins.less';
import '@/styles/fonts.css';
import '@/styles/globals.less';
import 'nprogress/nprogress.css';
import { App as AntdApp } from 'antd';
import ConfigProvider from '@/context/ConfigProvider';
import UserProvider from '@/context/UserProvider';
import withTheme from '@/theme';
import '../lib/hooks/common/firebaseConfig/init';
import { appWithTranslation } from 'next-i18next';
import { Locale } from 'antd/lib/locale';
import { CurrentLanguage } from '@/shared/language';
// import Login from '@/components/Login';
// import ArticleBottomAlert from '@/components/Common/ArticleBottomAlert';
// import CookieConsentContent from '@/components/Common/CookieConsent';
// import nextI18nConfig from '../../next-i18next.config'
import dynamic from 'next/dynamic';
import LoginResult from '@/components/LoginResult';

const ArticleBottomAlert = dynamic(() => import('@/components/Common/ArticleBottomAlert'), {
  ssr: false,
});
// const CookieConsentContent = dynamic(() => import('@/components/Common/CookieConsent'), {
//   ssr: false,
// });
const Login = dynamic(() => import('@/components/Login'), {
  ssr: false,
});

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeError', NProgress.done);
Router.events.on('routeChangeComplete', NProgress.done);

require('../styles/hs-theme.less');

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  return withTheme(
    <AntdApp>
      <ConfigProvider>
        <UserProvider>
          {getLayout(<Component {...pageProps} />)}
          <Login />
          <LoginResult />
          <ArticleBottomAlert />
          {/* <CookieConsentContent /> */}
        </UserProvider>
      </ConfigProvider>
    </AntdApp>,
    loc,
  );
}

export default appWithTranslation(App);
