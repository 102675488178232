import axios from '@/shared/axios';
import {
  FeedbackDetailModel,
  HairCollectListModel,
  HairCreateResultModel,
  HairLibPublicModel,
  HairPayItemModel,
  HairRecordsBalanceListModel,
  HairRecordsBalanceModel,
  HairShareInfoModel,
  HairShareListModel,
  HairUploadImageModel,
} from '@/types/hairstyle';
import { OrderModel } from '@/types/home';
import { BaseResponse } from '@/types/web.interface';

// 发型库
export async function fetchHairstyleLibPubData(gender?: 'male' | 'female' | any, locale?: string) {
  const res = await axios.get<BaseResponse<[HairLibPublicModel]>>(
    `/hairstyle/lib/public/${gender}`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 个人发型库
export async function fetchHairstyleLibMineData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairLibPublicModel]>>(`/hairstyle/mine/list/collect`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// update image
export const updateImageData = async (file: File, type: 'mine' | 'lib' | string) => {
  if (file === null) {
    return {
      code: 0,
      message: '',
      data: null,
    };
  }

  const formData = new FormData();
  formData.append('image', file);

  try {
    const res = await axios.post<BaseResponse<HairUploadImageModel>>(
      `/hairstyle/upload/${type}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return res.data;
  } catch (error) {
    return {
      code: -1,
      message: 'error',
    };
  }
};

// 创作
export async function fetchHairstyleCreateData(your_image?: any, lib_ids?: any[], locale?: string) {
  const res = await axios.post<BaseResponse<[HairCreateResultModel]>>(
    `/hairstyle/create`,
    {
      your_image,
      lib_ids,
    },
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 商品列表
export async function fetchHairstyleItemListData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairPayItemModel]>>(`/hairstyle/item/list`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 购买
export async function orderSubsRequest(itemId: any, redirectUrl?: string | string[]) {
  const res = await axios.post<BaseResponse<OrderModel>>(
    `/hairstyle/item/buy`,
    {
      item_id: itemId,
      redirect_url: redirectUrl,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// 邮件通知开关
export async function fetchHairstyleEmailNotifyOperateData(
  operate?: 'open' | 'close' | any,
  locale?: string,
) {
  const res = await axios.post<BaseResponse<any>>(
    `/hairstyle/item/email/notify/${operate}`,
    {
      operate,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// 点数余额
export async function fetchHairstyleRecordsBalanceData(locale?: string) {
  const res = await axios.get<BaseResponse<HairRecordsBalanceModel>>(`/hairstyle/item/balance`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 点数记录
export async function fetchHairstyleRecordsBalanceListData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairRecordsBalanceListModel]>>(
    `/hairstyle/item/balance/records`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 文章数据
export async function fetchHairstyleArticleData(keyword?: string, locale?: string) {
  const res = await axios.get<BaseResponse<any>>(`/hairstyle/article/data/${keyword}`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 创作列表
export async function fetchHairstyleCreateListData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairCreateResultModel]>>(
    `/hairstyle/mine/list/create`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 查看分享内容
export async function fetchHairstyleMineShareData(linkId?: any, locale?: string) {
  const res = await axios.get<BaseResponse<HairShareInfoModel>>(`/hairstyle/mine/share/${linkId}`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 单张删除
export async function fetchHairstyleCreateDeleteData(createId?: any, locale?: string) {
  const res = await axios.post<BaseResponse<any>>(`/hairstyle/create/delete/${createId}`, {
    headers: {
      'Current-Language': locale,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data;
}

// cancel
export async function fetchHairstyleOrderCancelData(reasonType?: any, locale?: string) {
  const res = await axios.post<BaseResponse<any>>(
    `/hairstyle/item/subs/cancel`,
    {
      reasonType,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// resume
export async function fetchHairstyleOrderResumeData(locale?: string) {
  const res = await axios.post<BaseResponse<any>>(`/hairstyle/item/subs/resume`, {
    headers: {
      'Current-Language': locale,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data;
}

// 创作结果查询，多id
export async function fetchHairstyleCreateResultsData(create_ids: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HairCreateResultModel]>>(`/hairstyle/create/results`, {
    params: {
      create_ids: `${create_ids}`,
    },
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 分享点赞、点踩
export async function fetchHairstyleMineShareOperateData(
  linkId?: any,
  share_image_id?: any,
  operate?: 'like' | 'dislike',
  locale?: string,
) {
  const res = await axios.post<BaseResponse<any>>(
    `/hairstyle/mine/share/operate/${linkId}`,
    {
      operate,
      share_image_id,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// 分享列表
export async function fetchHairstyleMineListShareData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairShareListModel]>>(`/hairstyle/mine/list/share`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

// 收藏列表
export async function fetchHairstyleMineListCollectData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairCollectListModel]>>(
    `/hairstyle/mine/list/collect`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 收藏操作
export async function fetchHairstyleMineCollectOperateData(
  hairstyle_id?: any,
  operate?: 'add' | 'remove',
  locale?: string,
) {
  const res = await axios.post<BaseResponse<any>>(
    `/hairstyle/mine/collect/${operate}`,
    {
      hairstyle_id,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// 移除未读消息
export async function fetchHairstyleRemoveUnreadData(shareId?: any, locale?: string) {
  const res = await axios.post<BaseResponse<any>>(`/hairstyle/mine/share/unread/${shareId}`, {
    headers: {
      'Current-Language': locale,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return res.data;
}

// 分享创建
export async function fetchHairstyleCreateShareData(params?: any, locale?: string) {
  const res = await axios.post<BaseResponse<HairShareInfoModel>>(
    `/hairstyle/mine/share/create`,
    {
      ...params,
    },
    {
      headers: {
        'Current-Language': locale,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// 随机发型数据
export async function fetchHairstyleLibRandomData(type: string, params?: any) {
  const res = await axios.get<BaseResponse<[HairLibPublicModel]>>(`/hairstyle/lib/random/${type}`, {
    params,
  });
  return res.data;
}

// 上一次创作的图片
export async function fetchHairstyleLastImageData(locale?: string) {
  const res = await axios.get<BaseResponse<string>>(`/hairstyle/user/last_image`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function fetchFeedBackData(locale?: string) {
  const res = await axios.get<BaseResponse<FeedbackDetailModel>>(`/survey/get`, {
    headers: {
      'Current-Language': locale,
    },
  });
  return res.data;
}

export async function updateFeedbackData(params?: any) {
  const res = await axios.post<BaseResponse<any>>(`/survey/answer`, {
    ...params,
  });
  return res.data;
}
