import axiosInstance from "@/shared/axios";

import axios from 'axios';
import { BaseResponse } from '@/types/web.interface';

export async function getArticleDetailContent(url: string) {
  const res = await axios.get<any>(`${url}`);
  return res;
}

// 文章内发行收藏

export async function postArticImageSelect(url: string, locale?:string) {
  const res = await axiosInstance.post<BaseResponse<any>>(`/hairstyle/article/collect?image_url=${url}`, {
    "Content-Type":'application/x-www-form-urlencoded'
  });
  return res;
}