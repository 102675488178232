import axios from '@/shared/axios';
import {
  HairLibPublicModel,
  HairShareBloggerInfoModel,
  HairstyleBloggerLabelModel,
  HairstyleCatalogDetailModel,
  HairstyleCatalogInfoModel,
} from '@/types/hairstyle';
import { BaseResponse } from '@/types/web.interface';

// 发型 lib labels
export async function fetchHairstyleLibPublicData(
  params?: any,
  locale?: string
) {
  const res = await axios.get<BaseResponse<[HairLibPublicModel]>>(
    `/hairstyle/lib/public`,
    {
      params,
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}


// 发型 lib labels
export async function fetchHairstyleLibLabelsData(blogger_id: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HairstyleBloggerLabelModel]>>(
    `/hairstyle/lib/labels`,
    {
      params: {
        blogger_id,
      },
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 发型 label data
export async function fetchHairstyleLabelData(label: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HairLibPublicModel]>>(
    `/hairstyle/lib/public/${label}`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 网红 labels
export async function fetchHairstyleBloggerLabelsData(locale?: string) {
  const res = await axios.get<BaseResponse<[string]>>(
    `/hairstyle/blogger/labels`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 发型 blogger page data
export async function fetchHairstyleBloggerInfoPageData(
  nickname: any,
  page_no: number = 1,
  page_size: number = 10,
  label?: string,
  locale?: string
) {
  const res = await axios.get<BaseResponse<[HairLibPublicModel]>>(
    `/hairstyle/blogger/info/page/${nickname}`,
    {
      params: {
        page_no,
        page_size,
        label
      },
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 发型 blogger info data
export async function fetchHairstyleBloggerInfoData(nickname: any, locale?: string) {
  const res = await axios.get<BaseResponse<HairShareBloggerInfoModel>>(
    `/hairstyle/blogger/info/${nickname}`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 用户提交链接
export async function fetchHairstyleBloggerUserSubmitData(link?: any, locale?: string) {
  const res = await axios.post<BaseResponse<any>>(
    `/hairstyle/blogger/user/submit`,
    {
      link,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// 搜索分页查询
export async function fetchHairstyleBloggerPageSearchData(params: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HairShareBloggerInfoModel]>>(
    `/hairstyle/blogger/page`,
    {
      params,
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// 用户follower
export async function fetchHairstyleBloggerFollowSubmitData(isFollow: boolean, nickname: any, locale?: string) {
  const res = await axios.post<BaseResponse<any>>(
    isFollow ? `/hairstyle/blogger/follow` : `/hairstyle/blogger/unfollow`,
    {
      nickname,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}

// followed list
export async function fetchHairstyleMineFollowedData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairShareBloggerInfoModel]>>(
    `/hairstyle/mine/followed`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// catalogs list
export async function fetchHairstyleMineCatalogsData(locale?: string) {
  const res = await axios.get<BaseResponse<[HairstyleCatalogInfoModel]>>(
    `/hairstyle/mine/catalogs`,
    {
      headers: {
        'Current-Language': locale,
      },
    },
  );
  return res.data;
}

// catalog detail info
export async function fetchHairstyleCatalogInfoData(catalog_period: any, locale?: string) {
  const res = await axios.post<BaseResponse<[HairstyleCatalogDetailModel]>>(
    `/hairstyle/mine/info/catalog`,
    {
      catalog_period,
    },
    {
      headers: {
        'Current-Language': locale,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return res.data;
}