import React from 'react';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/lib/locale';

const withTheme = (node: JSX.Element, loc: Locale) => (
  <ConfigProvider
    locale={loc}
    prefixCls="next-antd"
    iconPrefixCls="next-antd-icon"
    theme={{
      token: {
        colorPrimary: '#9873FF',
        borderRadius: 5,
        fontFamily: 'Poppins',
        fontSize: 16,
        colorLink: '#9873FF',
      },
      components: {},
    }}
  >
    <ConfigProvider>{node}</ConfigProvider>
  </ConfigProvider>
);

export default withTheme;
